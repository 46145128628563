<template>
  <div>
    <div class="col-md-12" style="height: 50px;
							background-color: #025ba6;
							color: white; "  >
      <a type="button" class="close" data-dismiss="modal" aria-label="Close" style="font-size: 40px;
      font-weight: 100;
      padding: 5px;
      color: white;
      opacity: 1;"><span aria-hidden="true">&times;</span></a>
      <h4 class=" " style="margin-top:10px; padding:5px;"><strong>EMI for LKR. 75,000</strong></h4>							 
    </div>
    <div class="col-md-12" style="padding:5px;" >
      <div v-for="(item, index) in loandata" :key="index" @click="loanAccordian(index)">
        <div v-if="item.show" class="col-md-12" style="border-bottom: 1px solid rgb(238, 238, 238); margin-bottom: 10px; margin-left: 10px;">
          <div class="col-md-1">
            <img :src="item.logo" style="top: 0; height: 40px; width: 40px; position: absolute;">
          </div>
          <div class="col-md-2">
            <h3>{{item.title}}</h3>
          </div>
          <div class="col-md-9 text-right">
            <i v-if="item.selected" class="fa fa-chevron-down" style="right: 0; background: #ffffff; color: #666666; padding: 20px;"></i>
            <i v-else class="fa fa-chevron-up" style="right: 0; background: #ffffff; color: #666666; padding: 20px;"></i>
          </div> 
          <div class="col-md-12" v-if="item.selected">
            <div class="col-md-12" style="background: white; border: 0px;"> 
              <div class="row">
                <div class="col-md-12">
                  <h4>You have been granted <span style="color:rgb(2, 91, 166);">LKR. 100,000</span> loan from SLT National eCommerce Platform </h4>
                </div>
                <div class="col-md-12">
                <div class="progress" style="height:10px; margin-top:8px; margin-bottom:8px;">
                <div class="progress-bar" id="startcount_2"  role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 30%;"></div>
                </div>
                </div> 
              </div>
              <div class="row">  
                <div class="col-md-6">
                  LKR. 2,500
                </div>
                <div class="col-md-6 text-right">
                  LKR. 100,000
                </div>
              </div> 
              <div class="row">  
                <div class="col-md-12"> 
                  <h4 style="background: #82bfff; height: 40px; text-align: center; line-height: 40px; color: #005284;"> You have 12 days to utilize this amount</h4>
                </div>
              </div> 
              <div class="row">  
                <div class="col-md-12"> 
                  <table class="table" > 
                    <tr style="background-color: gainsboro;">
                      <th></th>
                      <th>Plan</th>
                      <th>Total Interest</th>
                      <th>EMI</th>
                    </tr>
                    <tr style="height:30px;">
                      <td class="text-center  checkboxes_list" style="vertical-align: middle; ">
                        <input type="checkbox" checked id="_pindex" value="true" />
                        <label style="display:inline; padding-bottom: 15px; margin: 0;" for="_pindex" ></label>				
                      </td>  
                      <td class="text-center">3 months</td>
                      <td class="text-center">LKR 250</td>
                      <td class="text-center">LKR 25,000</td>
                    </tr>
                    <tr style="height:30px;">
                      <td class="text-center  checkboxes_list" style="vertical-align: middle; ">
                        <input type="checkbox" checked id="_pindex" value="true" />
                        <label style="display:inline; padding-bottom: 15px; margin: 0;" for="_pindex" ></label>				
                      </td>  
                      <td class="text-center">3 months</td>
                      <td class="text-center">LKR 250</td>
                      <td class="text-center">LKR 25,000</td>
                    </tr>
                    <tr style="height:30px;">
                      <td class="text-center  checkboxes_list" style="vertical-align: middle; ">
                        <input type="checkbox" checked id="_pindex" value="true" />
                        <label style="display:inline; padding-bottom: 15px; margin: 0;" for="_pindex" ></label>				
                      </td>  
                      <td class="text-center">3 months</td>
                      <td class="text-center">LKR 250</td>
                      <td class="text-center">LKR 25,000</td>
                    </tr>
                  </table>
                </div>
              </div> 
              <div class="row">  
                <div class="col-md-12"> 
                  <h6>* Shipping fee also included.</h6>
                </div>
              </div> 
              <div class="row">  
                <div class="col-md-2 text-center checkboxes_list"> 
                  <input type="checkbox" checked id="_pindex2" value="true" />
                  <label style="display:inline; padding-bottom: 15px; margin: 0;" for="_pindex2" ></label>	
                </div>
                <div class="col-md-10"> 
                  <h5>Simply add your product to the basket and Proceed to Checkout to apply.</h5>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      
      
      <!-- <h3  style="background-color: #ffffff;"><span> SLT </span><img src="https://www.slt.lk/sites/all/themes/slt/images-home/slt-log.svg" style="top: 0; height: 40px; width: 40px; position: absolute;"><i class="fa fa-plus-square" style="right: 0; background: #ffffff; color: #666666; font-size: 28px;"></i></h3>		 -->
    </div>
  </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/buyer/APIProfile";
import { async } from 'q';
export default {
  mixins: [mixinCommon, mixinProfile],
  data: function () {
    return {
      loandata: [
        {
          logo: "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1593920318/slt/portal_user/1593920318516.png",
          title: 'SLT',
          selected: false,
          show: true,
        },
        {
          // logo: "https://upload.wikimedia.org/wikipedia/en/f/f8/Mobitel_logo.png",
          title: 'Mobitel',
          selected: false,
          show: true,
        }
      ]
    }
  },
  created(){ 
  },
  methods: {
    loanAccordian(index){ 
        let showlist = true;
        this.loandata.forEach((data, indexfor) => { 
          
          if(index === indexfor){
            if(data.selected !== true){
              data.selected = true; 
            } else {
              data.selected = false; 
              showlist = true
            }
            
          }

          if (data.selected === true) {
            showlist = false
          }   
        });

        if(showlist === false){
          this.loandata.forEach((data, indexfor) => { 
            if(index !== indexfor){
              data.show = false;
            }
          });
        } else {
          this.loandata.forEach((data, indexfor) => { 				 
            data.show = true;				 
          });
        }

      }
    }
}
</script>
