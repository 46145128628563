<template>
  <div>
    <div class="col-md-12 col-sm-12" style="margin-top:30px;">
      <div class="col-md-5 col-sm-5"><strong>1)&nbsp; Seller Communication ?</strong></div>
      <div class="col-md-4 col-sm-4">
        <div class="rating-box">
          <CommonRatingStars v-bind:starsize="'12px'" v-bind:stargap="'2px'" v-bind:rating="Math.floor(seller_rating)" @clicked="onClickSubRatings1"/>
        </div>
      </div>
      <div class="col-md-5 col-sm-5"><strong>2)&nbsp; Shipping Time ?</strong></div>
      <div class="col-md-4 col-sm-4">
        <div class="rating-box">
          <CommonRatingStars v-bind:starsize="'12px'" v-bind:stargap="'2px'" v-bind:rating="Math.floor(shipping_rating)" @clicked="onClickSubRatings2"/>
        </div>
      </div>
      <div class="col-md-5 col-sm-5"><strong>3)&nbsp; Product Qulity ?</strong></div>
      <div class="col-md-4 col-sm-4">
        <div class="rating-box">
          <CommonRatingStars v-bind:starsize="'12px'" v-bind:stargap="'2px'" v-bind:rating="Math.floor(product_quality_rating)" @clicked="onClickSubRatings3"/>
        </div>
      </div>
    </div>
    <div class="col-md-7">
        <h2 id="review-title">Write a review</h2>
        <div class="contacts-form"> 
            <div class="form-group"> <span class="icon icon-bubbles-2"></span>
                <textarea class="form-control" name="text" v-model="comment" onblur="if (this.value == '') {this.value = 'Your Review';}" onfocus="if(this.value == 'Your Review') {this.value = '';}">Your Review</textarea>
            </div>  
            
            <div class="form-group">
            <b>Rating : {{rating}}</b><span class="fa fa-stack main-rate-star"> <i style="color: #fec42d;"  class="fa fa-star fa-stack-1x"></i></span>         
            </div>
            
            <div class="col-md-5" v-for="(item, index) in image" :key="index">
              <div class="row col-md-11" style="padding-left: 0px;">
                <img data-index="1"  :src="item"  class="img thumbnail">
              </div> 
              <div class="row">
                <button class="btn btn-sm btn-danger" @click="clearImage(index)"><li class="fa fa-close"></li></button>
              </div>
            </div> 
            <div class="col-md-5" v-if="isLoading"> 
              <div class="row col-md-11" style="padding-left: 0px;">
                <img data-index="1"  src="assets/image/product/loading.gif"  class="img thumbnail">
              </div> 
            </div> 
            <div class="col-md-12">
              <div class="row">
                <div class="form-group">        
                  <label>Upload Image</label>    
                  <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile" :disabled="uploadDisable">   											
                </div>
                <div class="btn-group">
                  <div class="buttons ">
                    <a @click.prevent.stop="cancelRatingReview" id="button-review" class="btn buttonGray">Cancel</a>
                    <a @click.prevent.stop="submitRatingReview" id="button-review" class="btn buttonGray">Submit Rating</a> 
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>	 
  </div>
</template>
<script>
import mixinProduct from "@/mixins/buyer/APIProduct";
import CommonRatingStars from '@/components/common/commonRatingStars.vue'


export default {
    name: 'CommonRatingInput',
    mixins: [mixinProduct],
    components: {
      CommonRatingStars
    },
    props: { 
      rating: Number,
      commententred: String,
    },
    data(){
      return{
        seller_rating: 0,
        shipping_rating: 0,
        product_quality_rating: 0, 
        comment: '',
        basicInfo: {},
        fileUploadFormData: new FormData(),
        image: [],
        uploadDisable: false,
        isLoading: false
      }
    },
    created(){ 
         
    },
    watch:{
     comment: function(val){
       this.emitComment(val);
     },
     commententred: function(val){
       this.comment = this.commententred;
     },
     image: function(val){
       if(this.image.length > 2){
         this.uploadDisable = true;
       } else {
         this.uploadDisable = false;
       }
     },
    },
    methods:{ 
      submitRatingReview(){
        this.$parent.submitRatingReview(false, this.image, this.seller_rating, this.shipping_rating, this.product_quality_rating); 
      },
      onClickSubRatings1 (value, type) { 
        if(this.$store.state.buyer_accessToken === null){
          this.$swal.fire({
              position: 'center',
              type: 'error',
              title: "Please Login To Rate Products!",
              showConfirmButton: false,
              timer: 1500
            })    
            
            return ;
        }
        if(!this.$parent.selectedProduct.have_order){
          this.$swal.fire({
              position: 'top-right',
              type: 'error',
              title: "Please Purchase This Product To Rate !",
              showConfirmButton: false,
              timer: 1500
              })   

            return ;
        } 
        this.seller_rating = value;
      },
       onClickSubRatings2 (value, type) { 
        if(this.$store.state.buyer_accessToken === null){
          this.$swal.fire({
              position: 'center',
              type: 'error',
              title: "Please Login To Rate Products!",
              showConfirmButton: false,
              timer: 1500
            })    
            
            return ;
        }
        if(!this.$parent.selectedProduct.have_order){
          this.$swal.fire({
              position: 'top-right',
              type: 'error',
              title: "Please Purchase This Product To Rate !",
              showConfirmButton: false,
              timer: 1500
              })   

            return ;
        } 
        this.shipping_rating = value;
      },
       onClickSubRatings3 (value, type) { 
        if(this.$store.state.buyer_accessToken === null){
          this.$swal.fire({
              position: 'center',
              type: 'error',
              title: "Please Login To Rate Products!",
              showConfirmButton: false,
              timer: 1500
            })    
            
            return ;
        }
        if(!this.$parent.selectedProduct.have_order){
          this.$swal.fire({
              position: 'top-right',
              type: 'error',
              title: "Please Purchase This Product To Rate !",
              showConfirmButton: false,
              timer: 1500
              })   

            return ;
        } 
        this.product_quality_rating = value;
      },
      cancelRatingReview(){
        this.$parent.cancelRatingReview(); 
      }, 
      emitComment(val){
        this.$emit('ratingcomment', val)
      },
      clearImage(index){ 
        this.image.splice(index, 1);
        document.getElementById("embedpollfileinput").value = "";
        this.fileUploadFormData = new FormData();
      },
      getFile: async function(eventf) {
        this.isLoading = true;
        await this.fileUploadFormData.append("image", eventf.target.files[0]);

        var tgt = eventf.target || window.event.srcElement;
        let files = tgt.files;

        let self = this;
        if (FileReader && files && files.length) {
          var fr = new FileReader();
          fr.onload = function() {
            // self.image = fr.result;
          };
          fr.readAsDataURL(files[0]);
        }

        try {
          let formData = await this.fileUploadFormData;
          let response = await self.putReviewImage(formData); 
          this.image.push(response.image);
          this.isLoading = false;
          this.fileUploadFormData = new FormData();
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      }     
    }
}
</script>
<style>
</style>
