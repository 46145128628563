<template>
    <div>
        <h2 style="font-weight: 600; 400; color: #191919;">Related Products</h2>
        <div v-if="blockLoading" id="myCarousel" class="carousel slide" data-ride="carousel">

            <!-- Wrapper for slides -->
            <div class="carousel-inner">

            <div :class="index1 === 0 ? 'item active' : 'item'" v-for="(item, index1) in relatedProducts.array"
                    :key="index1">
                <div v-for="(item1, index) in item"
                    :key="index">
                    <div class="item singalTile">         
                        <div class="item-inner product-layout transition product-grid">
                            <div class="product-item-container">
                                <div class="left-block left-b">
                                    <!-- <div class="box-label">
                                        <span class="label-product label-sale">-11%</span>
                                    </div> -->
                                    <div class="product-image-container second_img">
                                        <a @click="viewProduct(item1.item_id)" :title="item1.product_name">
                                            <img :src="item1.imgpath[0]" class="img-3 img-responsive" alt="image2" style="height: 180px; width: 180px; object-fit: cover;">
                                        </a>
                                    </div>
                                    <!--quickview--> 
                                    <div class="so-quickview">
                                        <!-- <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="quickview.html" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a> -->
                                        <a class="btn-button quickview quickview_handler visible-lg"></a>
                                    </div>                                                     
                                    <!--end quickview-->
                                </div>
                                <div class="carousel-caption right-block">
                                    <div class="button-group so-quickview cartinfo--left">
                                        <button @click="quickViewProductModal(item1.item_id)" type="button" class="addToCart" title="Add to cart">
                                            <span>Add to cart </span>   
                                        </button>
                                        <button
                                            v-if="item1.wishlit === false"
                                            @click="addToWishList(item1,item1.item_id)"
                                            type="button"
                                            class="wishlist btn-button addToWishList"
                                            title="Add to Wish List"
                                            >
                                            <i class="fa fa-heart-o"></i>
                                        </button>

                                        <button
                                            v-if="item1.wishlit === true"
                                            @click="removeFromWishList(item1,item1.item_id)"
                                            type="button"
                                            class="wishlist btn-button"
                                            style="color: #fff !important; background-color: #005baa !important"
                                            title="Remove to Wish List"
                                            >
                                            <i class="fa fa-heart-o"></i>
                                        </button>
                                    </div>
                                    <div class="caption hide-cont">
                                        <div class="ratings">
                                            <div class="rating-box">    
                                                <span class="fa fa-stack"><i class="fa fa-star" :class="item1.rating === 0 ? 'fa fa-star-o':'fa fa-star'"
                                                    style="
                                                    font-size: small;
                                                    color: rgb(255 205 5);
                                                    padding-right: 5px;
                                                    " ></i></span>
                                                <span class="fa fa-stack">{{item1.rating}}</span>
                                            </div>
                                            <span>( {{item1.rating}} )</span>
                                        </div>
                                        <h4 style="
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            font-weight: bold;"
                                        ><a @click="viewProduct(item1.item_id)" :title="item1.product_name">{{item1.product_name}}</a></h4>
                                    </div>
                                    <p class="price">
                                        <span class="price-new">Rs.{{item1.price}}</span>&nbsp;&nbsp;
                                        <span v-if="item1.prediscount !== 0 || item1.prediscount !== item1.price || item1.prediscount !== undefined" class="price-old"
                                        >Rs.{{item1.prediscount}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            </div>
            <!-- Indicators -->
            <ol class="carousel-indicators">
                <li :class="index === 0 ? 'active': ''" data-target="#myCarousel" v-for="(item, index) in relatedProducts.array"
                    :key="index" :data-slide-to="index"></li>
            </ol>

            <!-- Left and right controls -->
            <a class="left carousel-control" href="#myCarousel" data-slide="prev">
            <!-- <span class="glyphicon glyphicon-chevron-left"></span> -->
            <span class="sr-only">Previous</span>
            </a>
            <a class="right carousel-control" href="#myCarousel" data-slide="next">
            <!-- <span class="glyphicon glyphicon-chevron-right"></span> -->
            <span class="sr-only">Next</span>
            </a>
        </div>
        <buyer-login-modal ref="modalLogin" />
        </div>
</template>
<script>
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
import APIProduct from "@/mixins/buyer/APIProduct";

export default {
    name:"ProductboostrapSilider",
    mixins: [APIProduct],
    components: { BuyerLoginModal },
    props: {
        itemData: {
            type: Array,
        },
    },
    data() {
        return {
            blockLoading:false,
            productData: [],
            relatedProducts:[],
        }
    },
    created() {
        this.handleBlockData();
    },
    methods: {
        handleBlockData: function () {
            this.blockLoading = false;
            this.productData =  this.itemData[0].verticalBlockData;
            this.relatedProducts = this.itemData[0].verticalBlockData;
            let arrayOfArrays = [];
            this.relatedProducts.array = [];
            while (this.relatedProducts.length > 0) {
                let arrayElement = this.relatedProducts.splice(0,2);
                arrayOfArrays.push(arrayElement);
            }

            this.relatedProducts.array = arrayOfArrays;
            this.blockLoading = true;
        },

        viewProduct: function (item_id) {
            this.$router.push({
                name: "product",
                params: { purl: "samesite" },
                query: { pid: item_id },
            });
        },

        quickViewProductModal: function (pid) {
            if (localStorage.getItem("buyer_token")) {
                this.$parent.parentModalTrigger(pid);
            } else {
                this.$refs.modalLogin.showModal();
            }
        },

        addToWishList: async function (data, item_id) {
            if (this.$store.state.buyer_accessToken === null) {
                this.$swal.fire({
                position: "center",
                type: "error",
                title: "Please login to add to Wishlist!",
                showConfirmButton: false,
                timer: 1500,
                });

                return;
            } else {
                try {
                let wishlistItem = { product_id: item_id, status: 1 };
                let response = await this.saveWishliat(wishlistItem);
                this.$swal.fire({
                    position: "center",
                    type: "success",
                    title: "Product Added to Wishlist",
                    showConfirmButton: false,
                    timer: 1500,
                });
                data.wishlit = true;
                } catch (error) {
                throw error;
                }
            }
        },
        removeFromWishList: async function (data, item_id) {
            try {
                let wishlistItem = { product_id: item_id, status: 2 };
                let response = await this.saveWishliat(wishlistItem);
                this.$swal.fire({
                position: "center",
                type: "success",
                title: "Product Removed from Wishlist",
                showConfirmButton: false,
                timer: 1500,
                });
                data.wishlit = false;
            } catch (error) {
                throw error;
            }
        },
    }
}
</script>
<style scoped>
    .module {
        padding-left: 20%;
        padding-right: 20%;
    }
    .owl2-item .active {
        width: 190px !important;
    }

    .singalTile {
        border: 1px solid lightgray;
        /* box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px; */
        overflow: hidden;
        border-radius: 15px;
        width: 90% !important;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 10px;
        margin-left: 5%;
        margin-bottom: 20px;
    }

    .right-block .button-group .btn-button {
        border: 1px solid #005baa !important;
        display: inline-block !important;
        padding: 0 !important;
        background-color: transparent !important;
        border-radius: 50% !important;
        color: #005baa !important;
        width: 34px !important;
        height: 34px !important;
        line-height: 34px !important;
        text-align: center !important;
        margin: 0 1px !important;
        font-size: 14px !important;
    }

    .addToCart {
        font-size: 12px !important;
        font-weight: 600 !important;
        color: #fff !important;
        text-transform: capitalize !important;
        background-color: #005baa !important;
        border-radius: 18px !important;
        border: none !important;
        padding: 0 10px !important;
        height: 34px !important;
        line-height: 34px !important;
        line-height: 100% !important;
    }


    .carousel-caption {
        padding: 0px !important;
    }

    .carousel-indicators li {
        border: 1px solid #005baa !important;
    }

    .carousel-indicators .active {
        background-color:#005baa !important;
    }

    .carousel-control.left {
        background-image: none !important;
    }

    .carousel-control.right {
        background-image: none !important;
    }

    .carousel-caption {
        position: revert  !important;
        text-shadow: none  !important;
    }

    *:focus {
        outline: none !important;
    }
    
    .carousel-indicators {
        bottom: auto  !important;
    }

    @media screen and (min-width: 768px){
        .carousel-indicators {
            bottom: auto  !important;
        }
    }
</style>